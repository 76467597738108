@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multiple-carousel__arrow--left {
  left: -2% !important ;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important ;
}

/* scroll bar */

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 20px;
}

@media only screen and (max-width: 900px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
